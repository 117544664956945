.metismenu-icon{
  background-repeat: no-repeat !important;
  width: 20px !important;
  height: 20px !important;
  background-size: contain !important;
  transform: translate(40%, 35%);
  
  &.icon-arrow-circle-broken-right{
    background: url('../../../assets/Icons/SideMenu/arrow-circle-broken-right.svg');
  }
  &.icon-file-minus{
    background: url('../../../assets/Icons/SideMenu/file-minus.svg');
  }
  &.icon-file-plus{
    background: url('../../../assets/Icons/SideMenu/file-plus.svg');
  }
  &.icon-file{
    background: url('../../../assets/Icons/SideMenu/file.svg');
  }
  &.icon-home{
    background: url('../../../assets/Icons/SideMenu/home.svg');
  }
  &.icon-log-out{
    background: url('../../../assets/Icons/SideMenu/log-out.svg');
  }
  &.icon-passcode{
    background: url('../../../assets/Icons/SideMenu/passcode.svg');
  }
  &.icon-settings{
    background: url('../../../assets/Icons/SideMenu/settings.svg');
  }
  &.icon-tag{
    background: url('../../../assets/Icons/SideMenu/tag.svg');
  }
  &.icon-truck{
    background: url('../../../assets/Icons/SideMenu/truck.svg');
  }
  &.icon-user-square{
    background: url('../../../assets/Icons/SideMenu/user-square.svg');
  }
  &.icon-commission{
    background: url('../../../assets/Icons/SideMenu/commission.svg');
  }
  &.icon-setting-3{
    background: url('../../../assets/Icons/SideMenu/setting-3.svg');
  }
  &.icon-smartphone{
    background: url('../../../assets/Icons/SideMenu/smartphone.svg');
  }
}
