@import "../../stylesheets/layout/layout-variables";

.closed-sidebar{
  .custom-header-logo {
    padding-left: 1.5rem !important;
  }
}

.custom-header-logo {
  padding-left: calc( $app-sidebar-width + 1.5rem ) !important;
}

.custom-header-user {
  display: flex;
  padding-bottom: 8px;
  gap: 8px;
  font-size: 12px;
  border-bottom: 1px solid #eaecf0;
  align-items: center;
}

.custom-notification {
  .ant-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
}